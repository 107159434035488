<template>
	<div class="container flex f-justifyB f-align wrap">
		<!-- <div class="list-box1 f-center" @click="toPage('xy-qu', './quClothes')">脏衣取出</div> -->
		<div class="list-box1 f-center" @click="toPage('xy-cun', './shoesCode')">净衣入柜</div>
		<div class="list-box2 f-center" @click="$router.push('./xmList')">箱门列表</div>
		<div class="list-box3 f-center" @click="show = true">退出系统</div>
		
		<van-dialog v-model="show" width="900px" title="退出系统" confirmButtonText="是" confirm-button-color="#00BF75" :showCancelButton="true" cancelButtonText="否" cancelButtonColor="#F40017" @confirm="confirm">
			<div class="font48 f-center">是否要退出系统</div>
		</van-dialog>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				show: false,
				myContainer: ''
			}
		},
		mounted() {
			this.$emit('change', '')
		},
		methods: {
			toPage(type, url) {
				localStorage.setItem('type', type)
				this.$router.replace(url)
			},
			confirm() {
				AndroidInterFace.minimize()
			},
		},
	}
</script>

<style scoped lang="scss">
	.container {
		padding: 120px 35px;
		
		.list-box1 {
			width: 450px;
			height: 230px;
			background: linear-gradient(270deg, #4E8CFF 0%, #216FFF 100%);
			border-radius: 80px 80px 0px 80px;
			font-size: 60px;
			color: #FFFFFF;
		}
		
		.list-box2 {
			width: 450px;
			height: 230px;
			background: linear-gradient(270deg, #FF377F 0%, #FF146A 100%);
			border-radius: 80px 80px 80px 0px;
			font-size: 60px;
			color: #FFFFFF;
		}
		
		.list-box3 {
			width: 450px;
			height: 230px;
			background: linear-gradient(270deg, #36C5BC 0%, #0AA69C 100%);
			border-radius: 80px 0px 80px 80px;
			font-size: 60px;
			color: #FFFFFF;
		}
		
		.list-box4 {
			width: 450px;
			height: 230px;
			background: linear-gradient(270deg, #FE9244 0%, #FB7818 100%);
			border-radius: 0px 80px 80px 80px;
			font-size: 60px;
			color: #FFFFFF;
		}
		
		.list-box5 {
			width: 930px;
			height: 140px;
			background: linear-gradient(270deg, #B34EF5 0%, #8A18D8 100%);
			border-radius: 30px;
			font-size: 60px;
			color: #FFFFFF;
		}
	}

	::v-deep .van-dialog {
		border-radius: 30px;
	}
	
	::v-deep .van-dialog__header {
		font-size: 48px;
		line-height: 70px !important;
		padding-top: 48px;
		position: relative;
		padding-bottom: 0;
		color: #000000;
		font-weight: 400;
	}
	
	/* .van-dialog__header::after {
		position: absolute;
		left: 50%;
		transform: translateX(-50%);
		bottom: -4px;
		content: '';
		width: 210px;
		height: 7px;
		background-color: #FF8400;
	} */
	::v-deep .van-dialog__content {
		padding-top: 80px !important;
		padding-bottom: 110px !important;
		font-size: 45px;
		line-height: 63px;
		font-weight: 800;
		color: #000000;
	}
	
	::v-deep .van-dialog__cancel,
	::v-deep .van-dialog__confirm {
		height: 85px !important;
		font-size: 40px !important;
		border-top: 2px solid #AAAAAA;
	}
</style>
